@import "https://fonts.googleapis.com/css?family=Poppins:600,500,400,700|Radio+Canada:700";
@import "https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css";
* {
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
}

html, body {
  height: 100%;
  margin: 0;
}

button:focus-visible {
  outline: 2px solid #4a90e2 !important;
  outline: -webkit-focus-ring-color auto 5px !important;
}

a {
  text-decoration: none;
}
/*# sourceMappingURL=index.e40d9e5b.css.map */
